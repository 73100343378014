import axios from 'axios';
import profileUrls from '@/core/services/constants/ProfileUrls';

const profileService = {
    getPassengerProfile(success, error){
        axios.get(profileUrls.getProfile)
            .then(responseData => success(responseData.data.data))
            .catch( errorResponse => error(errorResponse.response.data.errors))
    },
    getCountryList(success, error){
        axios.get(profileUrls.getCountryList)
            .then(responseData => success(responseData.data.countries))
            .catch( errorResponse => error(errorResponse.response.data.errors))
    },
    updateProfile(data,success,error){
        axios.post(profileUrls.updateProfile,data)
            .then(responseData => success(responseData.data.passenger))
            .catch( errorResponse => {
                //console.log(errorResponse.response)
                error(errorResponse.response.data.message)
            })
    },
    getTicketDetails(ticketId,success, error){
        axios.get(profileUrls.getTicketDetails+ticketId)
            .then(responseData => success(responseData.data.data))
            .catch( errorResponse => error(errorResponse.response.data.errors))
    },
    getTicketList(params,success, error){
        let ticketStatus = '';
        if(params.status=='booked'){
            ticketStatus = '?status=booked';
        }
        axios.get(profileUrls.getTicketDetails+ticketStatus,params)
            .then(responseData => success(responseData.data))
            .catch( errorResponse => error(errorResponse.response.data.errors))
    },
    createInvoice(ticketId,success,error){
        axios.post(profileUrls.createInvoice+ticketId)
            .then( responseData => success(responseData.data))
            .catch( errorResponse => error(errorResponse.response.data.errors))
    },
    getTicketSummary(success,error){
        axios.get(profileUrls.getTicketSummary)
            .then( responseData => success(responseData.data))
            .catch( errorResponse => error(errorResponse.response.data.errors))
    },
    placeCancelRequest(requestData,ticketId,success,error) {
        axios.post(profileUrls.cancelTicketRequest + ticketId,requestData)
            .then( responseData => success(responseData.data))
            .catch( errorResponse => error(errorResponse.response.data))
    },
    cancelBooking(requestData,ticketId,success,error) {
        axios.post(profileUrls.cancelBookingRequest + ticketId, requestData)
            .then( responseData => success(responseData.data))
            .catch( errorResponse => error(errorResponse.response.data.errors))
    },
    logOut(success,error){
        axios.post(profileUrls.logOut)
            .then( responseData => success())
            .catch(errorResponse => error())
    }

};

export default profileService;





