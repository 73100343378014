export const profileUrls = {
    getProfile: "passenger/profile/",
    updateProfile: "passenger/update-profile/",
    getCountryList: "passenger/country/",
    getTicketDetails: "passenger/ticket/",
    createInvoice: "passenger/payment/invoice/",
    getTicketSummary: "passenger/ticket-summary/",
    cancelBookingRequest : "passenger/ticket/cancel/",
    cancelTicketRequest : "passenger/ticket/cancel-request/",
    logOut : "passenger/logout"


};
export default profileUrls;
