<template>
    <div class="card card-custom">
        <div class="card-header">
            <div class="card-title">
                <span class="card-icon">
                    <i class="fa fa-ticket-alt text-aqua"></i>
                </span>
                <h3 class="card-label text-uppercase">Ticket Details</h3>
            </div>
            <div class="card-title">
              <template v-if="ticket" class="text-center">
                <span v-if="ticket.status=='booked'" class="badge bg-warning rounded-pill text-capitalize">{{ ticket.status }}</span>
                <span v-if="ticket.status=='issued'" class="badge bg-light-green rounded-pill text-capitalize">{{ ticket.status }}</span>
                <span v-if="ticket.status=='cancelled' && ticket.issued_at==null" class="badge bg-danger rounded-pill text-capitalize">Expired</span>
                <span v-if="ticket.status=='cancelled' && ticket.issued_at!=null" class="badge bg-info rounded-pill text-capitalize">{{ ticket.status }}</span>
              </template>
            </div>
        </div>
        <div v-if="ticketLoaded" class="card-body mb-5">
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-6">
                           <p v-if="getCompanyInfo.id==1"><i class="fa fa-bus text-danger" style="width:30px; text-align:left;"></i><strong>{{ticket.company_name}}</strong></p>
                           <p><i class="fa fa-road" style="width:30px; text-align:left;"></i>{{ticket.route_name}} </p>
                           <p><i class="fa fa-bus" style="width:30px; text-align:left;"></i>{{ticket.coach_number}} </p>
                           <p><i class="fa fa-ticket-alt" style="width:30px; text-align:left;"></i>{{ticketId}}</p>
                           <p><i class="far fa-user" style="width:30px; text-align:left;"></i>{{ticket.passenger_name}}</p>
                           <p><i class="fas fa-mobile-alt" style="width:30px; text-align:left;"></i>{{ticket.passenger_mobile}} </p>
                           <p>
                               <i class="fa fa-sign-in-alt" style="width:30px; text-align:left;"></i><strong>{{ticket.boarding_station}} </strong>
                               <span class="badge bg-green rounded-pill">{{ticket.boarding_counter}}</span> {{ticket.boarding_place}}
                               <br/><i class="far fa-clock" style="width:30px; text-align:left;"></i><span class="text-green">{{moment(ticket.boarding_counter_time,"yyyy-MM-DD hh:mm:ss").format('D MMM yyyy hh:mm A')}}</span>
                           </p>
                           <p style="margin-bottom: 20px;">
                               <i class="fa fa-sign-out-alt" style="width:30px; text-align:left;"></i><strong>{{ticket.dropping_station}} </strong>
                               <span class="badge bg-red rounded-pill">{{ticket.dropping_counter}}</span> {{ticket.dropping_place}}
                           </p>
                           <!--BOOKED INFO-->
                           <small class="text-warning" v-if="ticket.status == 'booked' && ticket.ticketing_platform_id == null">
                               &star; Booked by <b>{{ticket.booked_by ? ticket.booked_by.name : ''}}</b> from <b>{{ticket.counter_name}}</b>
                               <br/>&star; Booked at <b>{{moment(ticket.booked_at,"yyyy-MM-DD hh:mm:ss").format('D MMM yyyy hh:mm A')}}</b>
                           </small>
                           <small class="text-warning" v-if="ticket.status == 'booked' && ticket.ticketing_platform_id != null">
                               &star; Booked by <b>Self</b> from <b>{{ticket.ticketing_platform ? ticket.ticketing_platform.name : ''}}</b>
                               <br/>&star; Booked at <b>{{moment(ticket.booked_at,"yyyy-MM-DD hh:mm:ss").format('D MMM yyyy hh:mm A')}}</b>
                           </small>
                           <span class="text-danger blink" v-if="ticket.status == 'booked' && ticket.ticketing_platform_id != null && showBookingCancelButton">
                               <br/><br/>
                               &malt; Will be expire at <b>{{moment(ticket.booked_at,"yyyy-MM-DD hh:mm:ss"). add(getCompanyInfo.general_settings.online_booking_lifetime, 'minutes').format('D MMM yyyy hh:mm A')}}</b>
                           </span>
                           <!--ISSUED INFO-->
                           <small class="text-success" v-if="ticket.status == 'issued' && ticket.ticketing_platform_id == null">
                               &bigstar; Issued by <b>{{ticket.issued_by ? ticket.issued_by.name : ''}}</b> from <b>{{ticket.counter_name}}</b>
                               <br/>&bigstar; Issued at <b>{{moment(ticket.issued_at,"yyyy-MM-DD hh:mm:ss").format('D MMM yyyy hh:mm A')}}</b>
                           </small>
                           <small class="text-success" v-if="ticket.status == 'issued' && ticket.ticketing_platform_id != null">
                               &bigstar; Issued by <b>Self</b> from <b>{{ticket.ticketing_platform ? ticket.ticketing_platform.name : ''}}</b>
                               <br/>&bigstar; Issued at <b>{{moment(ticket.issued_at,"yyyy-MM-DD hh:mm:ss").format('D MMM yyyy hh:mm A')}}</b>
                           </small>
                           <!--EXPIRED INFO-->
                           <small class="text-danger" v-if="ticket.status == 'cancelled' && ticket.issued_at == null && ticket.cancelled_by == null && ticket.online_cancelled_by == null">
                               &malt; Booking time expired <b>Auto Cancelled</b>
                               <br/>&malt; Expired at <b>{{moment(ticket.cancelled_at,"yyyy-MM-DD hh:mm:ss").format('D MMM yyyy hh:mm A')}}</b>
                           </small>
                            <small class="text-danger" v-if="ticket.status == 'cancelled' && ticket.issued_at == null && ticket.ticketing_platform_id == null && ticket.cancelled_by != null">
                                &malt; Booking cancelled by <b>{{ticket.cancelled_by ? ticket.cancelled_by.name : ''}}</b> from <b>{{ticket.counter_name}}</b>
                                <br/>&malt; Cancelled at <b>{{moment(ticket.cancelled_at,"yyyy-MM-DD hh:mm:ss").format('D MMM yyyy hh:mm A')}}</b>
                            </small>
                            <small class="text-danger" v-if="ticket.status == 'cancelled' && ticket.issued_at == null && ticket.ticketing_platform_id != null && ticket.online_cancelled_by != null">
                                &malt; Booking cancelled by <b>Self</b> from <b>{{ticket.ticketing_platform ? ticket.ticketing_platform.name : ''}}</b>
                                <br/>&malt; Cancelled at <b>{{moment(ticket.cancelled_at,"yyyy-MM-DD hh:mm:ss").format('D MMM yyyy hh:mm A')}}</b>
                            </small>
                           <!--CANCELLED INFO-->
                           <small class="text-danger" v-if="ticket.status == 'cancelled' && ticket.issued_at != null && ticket.ticketing_platform_id == null">
                               &malt; Cancelled by <b>{{ticket.cancelled_by ? ticket.cancelled_by.name : ''}}</b> from <b>{{ticket.counter_name}}</b>
                               <br/>&malt; Cancelled at <b>{{moment(ticket.cancelled_at,"yyyy-MM-DD hh:mm:ss").format('D MMM yyyy hh:mm A')}}</b>
                           </small>
                           <small class="text-danger" v-if="ticket.status == 'cancelled' && ticket.issued_at != null  && ticket.ticketing_platform_id != null">
                               &malt; Cancelled by <b>Self</b> from <b>{{ticket.ticketing_platform ? ticket.ticketing_platform.name : ''}}</b>
                               <br/>&malt; Cancelled at <b>{{moment(ticket.cancelled_at,"yyyy-MM-DD hh:mm:ss").format('D MMM yyyy hh:mm A')}}</b>
                           </small>
                           <!--CANCEL REQUEST INFO-->
                           <small class="text-info" v-if="ticket.cancel_requested == 1">
                               <br/><br/>
                               &malt; Ticket cancel request send at <b>{{moment(ticket.cancel_requested_at,"yyyy-MM-DD hh:mm:ss").format('D MMM yyyy hh:mm A')}}</b>
                               <br/>&star; Cancel Status <b class="text-capitalize">{{ticket.cancel_status}}</b>
                               <small v-if="ticket.cancel_comment" class="text-black-50"><i class="far fa-comments fa-1x ml-3"></i> {{ticket.cancel_comment}}</small>
                           </small>
                        </div>
                        <div class="col-md-6">
                           <table class="table table-bordered" style="margin-bottom: 0px;">
                               <tbody id="sell-seat-table">
                               <tr>
                                   <th width="50%" style="text-align:center">#</th>
                                   <th width="50%" style="text-align:center">SEAT</th>
                               </tr>
                               <tr v-for="(seat,index) in ticket.seat_numbers" :key="seat">
                                   <td style="text-align:center">{{index+1}}</td>
                                   <td class="text-green" style="text-align:center">{{seat}}</td>
                               </tr>
                               </tbody>
                               <tfoot>
                               <tr>
                                   <td style="text-align:right">Sub Total :</td>
                                   <td style="text-align:right">{{ticket.sub_total}} Tk
                                   </td>
                               </tr>
                               <tr>
                                   <td style="text-align:right">Discount :</td>
                                   <td style="text-align:right">{{ticket.discount_amount}} Tk
                                   </td>
                               </tr>
                               <tr>
                                   <td style="text-align:right">Service Charge : (+)</td>
                                   <td style="text-align:right" id="conf-service-charge">{{ticket.service_charge}}Tk
                                   </td>
                               </tr>

                               <!--<tr style="font-weight:bold;">
                   <td style="text-align:right" colspan="2">Discount : (-)</td>
                   <td style="text-align:right" id="conf-discount-amount"> Tk</td>
               </tr>-->
                               <tr style="font-weight:bold;">
                                   <td style="text-align:right">Grand Total :</td>
                                   <td style="text-align:right" id="conf-grand-total">{{ticket.grand_total}}Tk
                                   </td>
                               </tr>
                               </tfoot>
                           </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-7" v-if="ticket != null && ticket.status=='issued'">
                <div class="col-md-12" >
                    <div class="row text-right justify-content-end">
                      <div class="col-2 pr-3">
                        <button class="btn btn-primary btn-block" @click="printTicket()">Print Ticket</button>
                      </div>
                      <div class="col-2" v-if="showCancelRequestButton">
                          <button class="btn btn-danger btn-block" @click="showCancelModal()">Cancel Ticket</button>
                      </div>
                    </div>
                    <h4 v-if="ticket.online_payment != null" class="text-black-50 pt-5"><i class="fa fa-money-bill text-aqua"></i> PAYMENT DETAILS</h4>
                    <table v-if="ticket.online_payment != null" class="table table-bordered">
                        <tbody>
                        <tr>
                            <th>Method</th>
                            <th>Amount</th>
                            <th>Invoice Number</th>
                            <th>Transaction Id</th>
                            <th>Time</th>
                        </tr>
                        <tr v-if="ticket && ticket.online_payment">
                            <td>{{ticket.online_payment.payment_method}}</td>
                            <td>{{ticket.online_payment.amount}}</td>
                            <td>{{ticket.online_payment.invoice_number}}</td>
                            <td>{{ticket.online_payment.transaction_id}}</td>
                            <td>{{moment(ticket.online_payment.updated_at,"yyyy-MM-DD hh:mm:ss").format('D MMM yyyy hh:mm A')}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="d-flex justify-content-end mt-7" v-else>
                <div class="w-25 mr-5" v-if="showBookingCancelButton">
                    <button class="btn btn-primary btn-block" @click="showPayNowModal()">Pay Now</button>
                </div>
                <div>
                    <button class="btn btn-danger btn-block" v-if="showBookingCancelButton" @click="showBookingCancelModal()">Cancel Booking</button>
                </div>
            </div>
        </div>
        <div class="card-body" v-else>
            <div class="row text-center d-flex justify-content-center">
                Loading.....
            </div>
        </div>
        <div v-if="ticket != null" class="only-print" id="ticket-print-nonac">
          <div style="width: 48%; float: left;">
            <table class="pc-table" style="width:100%;">
              <tbody>
              <tr>
                <td width="100%" colspan="4" style="text-align: left; padding: 0px 0px 10px 10px;">
                  <img
                      :alt="getCompanyInfo.general_settings.site_name"
                      :src="getCompanyInfo.general_settings.logo"
                      style="max-height: 40px !important;"
                  />
                  <span style="font-size: 20px; line-height: 40px; vertical-align: top; padding-left: 10px;">{{getCompanyInfo.general_settings.site_name}}</span>
                </td>
              </tr>
              <tr>
                <td width="18%" style="text-align:right;">Coach:</td>
                <td width="32%" style="text-align:left;"><strong>{{ticket.coach_number}}</strong></td>
                <td width="18%" style="text-align:right;">Paribahan:</td>
                <td width="32%" style="text-align:left;"><strong>{{ticket.company_name}}</strong></td>
              </tr>
              <tr>
                <td width="18%" style="text-align:right;">Passenger:</td>
                <td width="32%" style="text-align:left; font-size:13px;"><strong class="name_print">{{ticket.passenger_name}}</strong></td>
                <td width="18%" style="text-align:right;">Route:</td>
                <td width="32%" style="text-align:left;">{{ticket.route_name}}</td>
              </tr>
              <tr>
                <td width="18%" style="text-align:right;">Mobile:</td>
                <td width="32%" style="text-align:left;"><strong>{{ticket.passenger_mobile}}</strong></td>
                <td width="18%" style="text-align:right;">PNR:</td>
                <td width="32%" style="text-align:left;"><strong>{{ticket.id}}</strong></td>
              </tr>
              <tr>
                <td width="18%" style="text-align:right;">Seats:</td>
                <td width="82%" colspan="3" style="text-align:left; font-size:13px;"><strong>[ {{ticket.seat_numbers.join(', ')}} ]</strong></td>
              </tr>
              <tr>
                <td width="18%" style="text-align:right;">Journey Date:</td>
                <td width="82%" colspan="3" style="text-align:left;"><strong>{{moment(ticket.boarding_counter_time,"yyyy-MM-DD hh:mm:ss").format('D MMM yyyy')}}</strong></td>
              </tr>
              <tr>
                <td width="18%" style="text-align:right;">Seat Fare:</td>
                <td width="82%" colspan="3" style="text-align:left;"><strong>{{ticket.seat_fares}} TK</strong></td>
              </tr>
              <tr>
                <td width="18%" style="text-align:right;">Total Fare:</td>
                <td width="82%" colspan="3" style="text-align:left;">{{ticket.seat_fares}} * {{ticket.total_seats}}{{ticket.goods_charge ? ' + '+ticket.goods_charge : '' }} = <strong>{{ticket.totalFareWithGoodsCharge}} TK</strong></td>
              </tr>
              <tr v-if="getCompanyInfo.general_settings.discount_show_in_ticket==1 && ticket.discount_amount>0">
                <td width="18%" style="text-align:right;">Discounted Fare:</td>
                <td width="32%" style="text-align:left;"><strong>{{ticket.grand_total}} TK</strong></td>
                <td width="18%" style="text-align:right;">Discount:</td>
                <td width="32%" style="text-align:left;"><strong>{{ticket.discount_amount}} TK</strong></td>
              </tr>
              <tr>
                <td width="18%" style="text-align:right;">From:</td>
                <td width="32%" style="text-align:left;"><strong>{{ticket.boarding_station}}</strong></td>
                <td width="18%" style="text-align:right;">To:</td>
                <td width="32%" style="text-align:left;"><strong>{{ticket.dropping_station}}</strong></td>
              </tr>
              <tr>
                <td style="text-align:right;">Boarding:</td>
                <td style="text-align:left;"><strong>{{ticket.boarding_counter}}</strong> {{ticket.boarding_place!=null ? "("+ticket.boarding_place+")" : ""}}</td>
                <td style="text-align:right;">Dropping:</td>
                <td style="text-align:left;"><strong>{{ticket.dropping_counter}}</strong> {{ticket.dropping_place!=null ? "("+ticket.dropping_place+")" : ""}}</td>
              </tr>
              <tr>
                <td style="text-align:right;">Departure:</td>
                <td style="text-align:left;"><strong>{{moment(ticket.boarding_counter_time,"yyyy-MM-DD hh:mm:ss").format('hh:mm A')}}</strong></td>
                <td style="text-align:right;">Reporting:</td>
                <td style="text-align:left;"><strong>{{moment(ticket.boarding_counter_time,"yyyy-MM-DD hh:mm:ss").subtract(20, 'minutes').format('hh:mm A')}}</strong></td>
              </tr>
              <tr>
                <td style="text-align:right;">Issued At:</td>
                <td style="text-align:left;">{{moment(ticket.issued_at,"yyyy-MM-DD hh:mm:ss").format('D MMM yyyy hh:mm A')}}</td>
                <td style="text-align:right;">Issued By:</td>
                <td style="text-align:left;">{{ticket.byName+ticket.counterManager+", "+ticket.fromName}}</td>
              </tr>
              <tr>
                <td style="text-align:right;">Printed By:</td>
                <td style="text-align:left;">Self</td>
                <td style="text-align:right;"></td>
                <td style="text-align:left;"></td>
              </tr>
              <tr>
                <td colspan="2" style="text-align:left; padding-top: 5px;">{{getCompanyInfo.domain}}</td>
                <td colspan="2" style="text-align:right; padding-top: 5px;">www.chokrojan.com</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div style="width: 52%; margin-top:50px; float: right;">
            <table style="width:100%; ">
              <tbody>
              <tr>
                <td style="text-align:right;">Paribahan:</td>
                <td style="text-align:left;"><strong>{{ticket.company_name}}</strong></td>
                <td style="text-align:right;">Paribahan:</td>
                <td style="text-align:left;"><strong>{{ticket.company_name}}</strong></td>
              </tr>
              <tr>
                <td style="text-align:right;">Route:</td>
                <td style="text-align:left;">{{ticket.route_name}}</td>
                <td style="text-align:right;">Route:</td>
                <td style="text-align:left;">{{ticket.route_name}}</td>
              </tr>
              <tr>
                <td style="text-align:right;">Coach:</td>
                <td style="text-align:left;"><strong>{{ticket.coach_number}}</strong></td>
                <td style="text-align:right;">Coach:</td>
                <td style="text-align:left;"><strong>{{ticket.coach_number}}</strong></td>
              </tr>
              <tr>
                <td style="text-align:right;">Passenger:</td>
                <td style="text-align:left; font-size:13px;"><strong class="name_print">{{ticket.passenger_name}}</strong></td>
                <td style="text-align:right;">Passenger:</td>
                <td style="text-align:left; font-size:13px;"><strong class="name_print">{{ticket.passenger_name}}</strong></td>
              </tr>
              <tr>
                <td style="text-align:right;">Mobile:</td>
                <td style="text-align:left;"><strong>{{ticket.passenger_mobile}}</strong></td>
                <td style="text-align:right;">Mobile:</td>
                <td style="text-align:left;"><strong>{{ticket.passenger_mobile}}</strong></td>
              </tr>
              <tr>
                <td width="18%" style="text-align:right;">Seats:</td>
                <td width="36%" style="text-align:left;"><strong>[ {{ticket.seat_numbers.join(', ')}} ]</strong></td>
                <td width="18%" style="text-align:right;">Seats:</td>
                <td width="28%" style="text-align:left;"><strong>[ {{ticket.seat_numbers.join(', ')}} ]</strong></td>
              </tr>
              <tr>
                <td style="text-align:right;">Seat Fare:</td>
                <td style="text-align:left;"><strong>{{ticket.seat_fares}} TK</strong></td>
                <td style="text-align:right;">Seat Fare:</td>
                <td style="text-align:left;"><strong>{{ticket.seat_fares}} TK</strong></td>
              </tr>
              <tr>
                <td style="text-align:right;">Total Fare:</td>
                <td style="text-align:left;">{{ticket.seat_fares}} * {{ticket.total_seats}}{{ticket.goods_charge ? ' + '+ticket.goods_charge : '' }} = <strong>{{ticket.totalFareWithGoodsCharge}} TK</strong></td>
                <td style="text-align:right;">Total Fare:</td>
                <td style="text-align:left;">{{ticket.seat_fares}} * {{ticket.total_seats}}{{ticket.goods_charge ? ' + '+ticket.goods_charge : '' }} = <strong>{{ticket.totalFareWithGoodsCharge}} TK</strong></td>
              </tr>
              <tr v-if="getCompanyInfo.general_settings.discount_show_in_ticket==1 && ticket.discount_amount>0">
                <td style="text-align:right;">Discounted Fare:</td>
                <td style="text-align:left;">{{ticket.totalFareWithGoodsCharge}} - {{ticket.discount_amount}} = <strong>{{ticket.totalDiscountedFare}} TK</strong></td>
                <td style="text-align:right;">Discounted Fare:</td>
                <td style="text-align:left;">{{ticket.totalFareWithGoodsCharge}} - {{ticket.discount_amount}} = <strong>{{ticket.totalDiscountedFare}} TK</strong></td>
              </tr>
              <tr>
                <td style="text-align:right;">Journey Date:</td>
                <td style="text-align:left;"><strong>{{moment(ticket.boarding_counter_time,"yyyy-MM-DD hh:mm:ss").format('D MMM yyyy')}}</strong></td>
                <td style="text-align:right;">Journey Date:</td>
                <td style="text-align:left;"><strong>{{moment(ticket.boarding_counter_time,"yyyy-MM-DD hh:mm:ss").format('D MMM yyyy')}}</strong></td>
              </tr>
              <tr>
                <td style="text-align:right;">Departure:</td>
                <td style="text-align:left;"><strong>{{moment(ticket.boarding_counter_time,"yyyy-MM-DD hh:mm:ss").format('hh:mm A')}}</strong></td>
                <td style="text-align:right;">Departure:</td>
                <td style="text-align:left;"><strong>{{moment(ticket.boarding_counter_time,"yyyy-MM-DD hh:mm:ss").format('hh:mm A')}}</strong></td>
              </tr>
              <tr>
                <td style="text-align:right;">PNR:</td>
                <td style="text-align:left;"><strong>{{ticket.id}}</strong></td>
                <td style="text-align:right;">PNR:</td>
                <td style="text-align:left;"><strong>{{ticket.id}}</strong></td>
              </tr>
              <tr>
                <td style="text-align:right;">From:</td>
                <td style="text-align:left;"><strong>{{ticket.boarding_station}}</strong></td>
                <td style="text-align:right;">From:</td>
                <td style="text-align:left;"><strong>{{ticket.boarding_station}}</strong></td>
              </tr>
              <tr>
                <td style="text-align:right;">To:</td>
                <td style="text-align:left;"><strong>{{ticket.dropping_station}}</strong></td>
                <td style="text-align:right;">To:</td>
                <td style="text-align:left;"><strong>{{ticket.dropping_station}}</strong></td>
              </tr>
              <tr>
                <td style="text-align:right;">Boarding:</td>
                <td style="text-align:left;"><strong>{{ticket.boarding_counter}}</strong> {{ticket.boarding_place!=null ? "("+ticket.boarding_place+")" : "" }}</td>
                <td style="text-align:right;">Boarding:</td>
                <td style="text-align:left;"><strong>{{ticket.boarding_counter}}</strong> {{ticket.boarding_place!=null ? "("+ticket.boarding_place+")" : "" }}</td>
              </tr>
              <tr>
                <td style="text-align:right;">Dropping:</td>
                <td style="text-align:left;"><strong>{{ticket.dropping_counter}}</strong> {{ticket.dropping_place!=null ? "("+ticket.dropping_place+")" : "" }}</td>
                <td style="text-align:right;">Dropping:</td>
                <td style="text-align:left;"><strong>{{ticket.dropping_counter}}</strong> {{ticket.dropping_place!=null ? "("+ticket.dropping_place+")" : "" }}</td>
              </tr>
              <tr>
                <td style="text-align:right;">Issued At:</td>
                <td style="text-align:left;">{{moment(ticket.issued_at,"yyyy-MM-DD hh:mm:ss").format('D MMM yyyy hh:mm A')}}</td>
                <td style="text-align:right;">Issued At:</td>
                <td style="text-align:left;">{{moment(ticket.issued_at,"yyyy-MM-DD hh:mm:ss").format('D MMM yyyy hh:mm A')}}</td>
              </tr>
              <tr>
                <td style="text-align:right;">Issued By:</td>
                <td style="text-align:left;">{{ticket.byName+ticket.counterManager+", "+ticket.fromName}}</td>
                <td style="text-align:right;">Issued By:</td>
                <td style="text-align:left;">{{ticket.byName+ticket.counterManager+", "+ticket.fromName}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

        <b-modal v-if="ticketLoaded" ref="payment-modal" hide-footer title="Pay Now">
            <div class="d-block text-center">
                <table class="table table-bordered">
                    <tbody>
                    <tr>
                        <td width="50%" style="color: #333333;">Ticket Number:</td>
                        <td width="50%" style="color: #333333;">{{ticketId}}</td>
                    </tr>
                    <tr>
                        <td style="color: #333333;">Coach Number:</td>
                        <td style="color: #333333;">{{ticket.coach_number}}</td>
                    </tr>
                    <tr>
                        <td style="color: #333333;">Journey Datetime:</td>
                        <td style="color: #333333;">{{moment(ticket.boarding_counter_time,"yyyy-MM-DD hh:mm:ss").format('D MMM yyyy')}} &nbsp;<i class="far fa-clock text-danger"> {{moment(ticket.boarding_counter_time,"yyyy-MM-DD hh:mm:ss").format('hh:mm A')}}</i></td>
                    </tr>
                    <tr>
                        <td style="color: #333333;">Seat Number:</td>
                        <td style="color: #333333;">
                            <span>{{ticket.seat_numbers.join(', ')}}</span>
                        </td>
                    </tr>
                    <tr>
                        <td style="color: #333333;">Fare:</td>
                        <td style="color: #333333;">{{ticket.grand_total}} Tk</td>
                    </tr>
                    </tbody>
                </table>
                <div class="d-flex justify-content-center">
                    <div class="w-100" v-if="redirectUrl != null">
                        <a :href="redirectUrl" target="_blank"><button class="btn btn-primary">Pay Now</button></a>
                    </div>
                </div>
            </div>
        </b-modal>

        <b-modal v-if="ticketLoaded" ref="cancel-modal" hide-footer title="Ticket Cancel Request">
            <div class="d-block text-center">
                <h3>Ticket Cancellation Policy</h3>
            </div>
            <div class="text-left pl-4">
                <ul>
                    <li>Cancel request only acceptable, before <b class="text-danger">{{getCompanyInfo.general_settings.online_cancel_allow_time}}</b> hours of bus journey start time.</li>
                    <li>Transport service provider company-wise return policy will be applied.</li>
                    <li><b class="text-danger">{{getCompanyInfo.general_settings.online_cancel_fine}}%</b> amount will be deducted from ticket value.</li>
                    <li>The service charge will not be refundable for online ticket cancellation and bus cancellation.</li>
                    <li>The refund process can take <b class="text-danger">2-5</b> working days to refund back to customer account.</li>
                    <li><b class="text-danger">Contact with support center mendatory +88 01763 484707</b></li>
                </ul>
            </div>
            <div class="text-center">
                <div class="form-group col-md-8 offset-md-2 mb-5">
                    <span>Ticket PIN: (sent to passenger ticket sms)</span>
                    <input v-model="pin" type="text" class="form-control" name="ticket_pin" id="ticket_pin" value="">
                </div>
            </div>
            <div class="text-right">
                <button class="btn btn-primary" @click="placeCancelRequest">Submit</button>
                <button class="btn btn-secondary px-7 ml-2" @click="cancelModalHide()" >Close</button>
            </div>
        </b-modal>

        <b-modal ref="cancel-booking-modal" hide-footer title="Cancel Booking Now">
           <div class="text-left">
               Are you sure you want to cancel this booked ticket?
           </div>
            <div class="text-right mt-7">
                <button class="btn btn-danger px-10" @click="cancelBooking()" >Yes</button>
                <button class="btn btn-secondary px-7 ml-2" @click="cancelBookingModalHide()" >No</button>
            </div>
        </b-modal>
    </div>
</template>

<script>
    import moment from 'moment/src/moment';
    import profileService from "@/core/services/models/profile.service";
    import {mapGetters} from 'vuex'

    export default {
        name: "TicketDetails",
        data(){
            return {
                ticket                  : null,
                ticketId                : this.$route.params.id,
                ticketLoaded            : false,
                redirectUrl             : null,
                showCancelRequestButton : false,
                showBookingCancelButton : false,
                pin                     : null,
            }
        },
        methods :{
            showPayNowModal(){
                this.$refs['payment-modal'].show();
                this.redirectUrl = null;
                profileService.createInvoice(this.ticketId, data => {
                    this.redirectUrl = data.data;
                })

            },
            showBookingCancelModal(){
                this.$refs['cancel-booking-modal'].show();
            },
            cancelBooking(){
              profileService.cancelBooking({},this.ticketId, data => {
                  this.$toasted.success("Booking cancelled");
                  this.$refs['cancel-booking-modal'].hide();
              })
            },
            cancelBookingModalHide(){
                this.$refs['cancel-booking-modal'].hide();
            },
            determineCancelRequestButton() {
                if(this.ticket.online_payment != null && this.ticket.cancel_requested==0){
                    let currentTime    = moment();
                    let maxAllowedTime = moment(this.ticket.boarding_counter_time);
                    maxAllowedTime.subtract(this.getCompanyInfo.general_settings.online_cancel_allow_time, 'hours');
                    this.showCancelRequestButton = !currentTime.isAfter(maxAllowedTime);
                }else {
                    this.showCancelRequestButton = false;
                }
            },
            determineCancelButton(){
                if(this.ticket.online_payment == null && this.ticket.status=='booked'){
                    let currentTime = moment();
                    let maxAllowedTime = moment(this.ticket.booked_at);
                    maxAllowedTime = maxAllowedTime.add(this.getCompanyInfo.general_settings.online_booking_lifetime, 'minutes');
                    if(currentTime.isBefore(maxAllowedTime))
                        this.showBookingCancelButton = true;
                }else {
                    this.showBookingCancelButton = false;
                }
            },
            showCancelModal(){
                this.$refs['cancel-modal'].show();
            },
            printTicket(){
              var printContents = document.getElementById("ticket-print-nonac").innerHTML;
              var popupWin = window.open('', '_blank', 'width=950,height=400,top=100,left=100,visible=none');
              popupWin.document.write('<html><head><style type="text/css">.only-print{display: inline;}  td { font-size:11px; padding: 0px 1px; vertical-align:top; line-height: 11px; } .pc-table td { font-size:11px; padding: 1px 1px; vertical-align:top; line-height: 11px;}</style></head><body>' + printContents + '</body></html>');
              popupWin.print();
            },
            placeCancelRequest(){
                profileService.placeCancelRequest({pin:this.pin},this.ticketId,
                    success => {
                        this.$toasted.success("Ticket Cancel Request Submitted Successfully.");
                        this.$refs['cancel-modal'].hide();
                        this.pin = null;
                    }, error => {
                        //console.log(errors.toString());
                        if(error.message!=undefined) {
                            this.$toasted.error(error.message);
                        }else if(error.errors){
                            this.$toasted.error(error.errors.pin.join('\n'));
                        }

                    }
                )
            },
            cancelModalHide(){
                this.$refs['cancel-modal'].hide();
            }
        },
        computed:{
            ...mapGetters(['getCompanyInfo']),
        },
        mounted(){
            profileService.getTicketDetails(this.$route.params.id, data => {
                this.ticket              = data;
                this.ticket.seat_numbers = this.ticket.seat_numbers.split(',');

                this.ticket.totalFareWithGoodsCharge = this.ticket.sub_total + this.ticket.goods_charge ?? 0;
                this.ticket.totalDiscountedFare      = this.ticket.totalFareWithGoodsCharge - this.ticket.discount_amount ?? 0;
                this.ticket.bookedByName = this.ticket.bookedBy ? this.ticket.bookedBy.name : "";
                this.ticket.issuedByName = this.ticket.issuedBy ? this.ticket.issuedBy.name : "";
                this.ticket.cancelledByName = this.ticket.cancelledBy ? this.ticket.cancelledBy.name : "";
                this.ticket.fromName = this.ticket.counter_name;
                this.ticket.counterManager = this.ticket.counter_manager;
                this.ticket.counterManager = (this.ticket.counterManager!=null) ? " ["+this.ticket.counterManager+"]" : "";
                if(this.ticket.ticketing_platform_id!=null){
                  this.ticket.bookedByName = this.ticket.issuedByName = "Self";
                  this.ticket.fromName = this.ticket.ticketing_platform.name;
                }
                this.ticket.byName = this.ticket.issuedByName;
                this.ticketLoaded        = true;
                this.determineCancelRequestButton();
                this.determineCancelButton();
            });

        }
    }
</script>

<style scoped>
.blink {
  animation: blinker 1s linear infinite;
}
#ticket-print-nonac td { font-size:10px; padding: 0px 2px; }
.only-print { display: none; }
@keyframes blinker {
  50% {
    opacity: 0.25;
  }
}
</style>
